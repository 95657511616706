import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import DeveloperReducer from './DeveloperReducer';
import HeaderSettingReducer from './Reducers/HeaderSettingReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    DeveloperReducer,
    HeaderSettingReducer
});

export default reducer;
