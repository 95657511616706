// assets
import { IconWindmill, IconWorld, IconBrandPagekit } from '@tabler/icons';

// constant
const icons = {
    IconWindmill,
    IconWorld,
    IconBrandPagekit
};

// ==============================|| SETTINGS MENU ITEMS ||============================== //

const settings = {
    id: 'settings',
    title: 'Settings',
    type: 'group',
    children: [
        {
            id: 'site',
            title: 'Header / Footer',
            type: 'collapse',
            icon: icons.IconWorld,
            children: [
                {
                    id: 'header-settings',
                    title: 'Header',
                    type: 'item',
                    url: '/settings/header',
                    breadcrumbs: false
                },
                {
                    id: 'footer-settings',
                    title: 'Footer',
                    type: 'item',
                    url: '/settings/footer',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'page',
            title: 'Homepage',
            type: 'collapse',
            icon: icons.IconBrandPagekit,
            children: [
                {
                    id: 'homepage-settings',
                    title: 'Homepage',
                    type: 'item',
                    url: '/settings/homepage',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default settings;
