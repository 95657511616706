import { UPDATE_HEADER_SETTING_ERROR, UPDATE_HEADER_SETTING_SUCCESS } from 'store/constant';

export const initialState = {
    isOpen: [], // for active default menu
    defaultId: 'default',
    opened: true
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const HeaderSettingReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_HEADER_SETTING_SUCCESS:
            return { success: true };
        case UPDATE_HEADER_SETTING_ERROR:
            return { error: action.payload };
        default:
            return state;
    }
};

export default HeaderSettingReducer;
