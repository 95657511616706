import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { ButtonBase } from '@mui/material';

// project imports
import config from 'config';
// import Logo from 'ui-component/Logo';
import { MENU_OPEN } from 'store/actions';
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
    const [content, setContent] = useState(null);
    useEffect(() => {
        if (!content) {
            axios(`${process.env.REACT_APP_API_KEY}/api/get/main/`).then(({ data }) => {
                console.log(data);
                setContent(data[0]);
            });
        }
    }, [content]);
    const defaultId = useSelector((state) => state.customization.defaultId);
    const dispatch = useDispatch();
    return (
        <ButtonBase disableRipple onClick={() => dispatch({ type: MENU_OPEN, id: defaultId })} component={Link} to={config.defaultPath}>
            {/* <Logo /> */}
            {content && <img src={process.env.REACT_APP_API_KEY + content.head_logo} alt="logo" style={{ width: '60%' }} />}
        </ButtonBase>
    );
};

export default LogoSection;
