import axiosClient from 'axios-client';
import {
    CLEAR_NOTIFICATION,
    GET_TOKEN,
    SET_NOTIFICATION,
    SET_TOKEN,
    TOKEN_ERROR,
    UPDATE_HEADER_SETTING_ERROR,
    UPDATE_HEADER_SETTING_SUCCESS
} from './constant';

// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';

export const set_token_handler = (values) => async (dispatch) => {
    const payload = {
        username: values.email,
        password: values.password
    };
    axiosClient
        .post('/users/login/', payload)
        .then(({ data }) => {
            localStorage.setItem('REAL_ESTATE_ACCESS_P_TOKEN', data.access);
            dispatch({
                type: SET_TOKEN,
                payload: data.access
            });
        })
        .catch(({ response }) => {
            console.log(response.data.detail);
            dispatch({
                type: TOKEN_ERROR,
                payload: response.data.detail
            });
        });
};

export const get_token_handler = () => async (dispatch) => {
    dispatch({
        type: GET_TOKEN
    });
};

export const set_notification_handler = (data) => async (dispatch) => {
    dispatch({
        type: SET_NOTIFICATION,
        payload: data
    });
};
export const set_clear_notification_handler = () => async (dispatch) => {
    dispatch({
        type: CLEAR_NOTIFICATION
    });
};

export const update_header_settings_request = (payload) => async (dispatch) => {
    const headers = {
        'Content-Type': 'multipart/form-data'
    };
    axiosClient
        .put('update/main/', payload, {
            headers: headers
        })
        .then(() => {
            dispatch({
                type: UPDATE_HEADER_SETTING_SUCCESS
            });
        })
        .catch(({ response }) => {
            dispatch({
                type: UPDATE_HEADER_SETTING_ERROR,
                payload: response.data.detail
            });
        });
};
