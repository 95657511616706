// action - state management
// import * as actionTypes from './actions';

import { SET_TOKEN, GET_TOKEN, TOKEN_ERROR, SET_NOTIFICATION, CLEAR_NOTIFICATION } from './constant';

export const initialState = {
    isOpen: [], // for active default menu
    defaultId: 'default',
    opened: true,
    token: localStorage.getItem('REAL_ESTATE_ACCESS_P_TOKEN')
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const DeveloperReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TOKEN:
            console.warn('error1', action);
            state.token = action.payload;
            return {
                ...state,
                token: action.payload,
                status: 200
            };
        case GET_TOKEN:
            state.token = localStorage.getItem('REAL_ESTATE_ACCESS_P_TOKEN');
            return {
                ...state
            };
        case TOKEN_ERROR:
            console.warn('error', action);
            return {
                tokenError: action.payload,
                status: 400
            };
        case SET_NOTIFICATION:
            return {
                notification: action.payload
            };
        case CLEAR_NOTIFICATION:
            return {
                notification: []
            };
        default:
            return state;
    }
};

export default DeveloperReducer;
