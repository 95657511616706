import { useContext, useState, createContext } from 'react';

const StateContext = createContext({
    user: null,
    ctoken: null,
    notification: null,
    setUser: () => {},
    setToken: () => {},
    setNotifications: () => {}
});

export const ContextProvider = ({ children }) => {
    const [user, setUser] = useState({});
    const [ctoken, _setToken] = useState(localStorage.getItem('REAL_ESTATE_ACCESS_P_TOKEN'));
    const [notification, _setNotification] = useState([]);

    const setToken = (ctoken) => {
        console.log(ctoken);
        _setToken(ctoken);
        if (ctoken) {
            localStorage.setItem('REAL_ESTATE_ACCESS_P_TOKEN', ctoken);
        } else {
            localStorage.removeItem('REAL_ESTATE_ACCESS_P_TOKEN');
        }
    };

    const setNotifications = (st, message) => {
        console.log(st);
        _setNotification([st, message]);
        setTimeout(() => {
            _setNotification([]);
        }, 1000);
    };

    return (
        <StateContext.Provider
            value={{
                user,
                ctoken,
                setToken,
                notification,
                setUser,
                setNotifications
            }}
        >
            {children}
        </StateContext.Provider>
    );
};

export const useStateContext = () => useContext(StateContext);

export default ContextProvider;
