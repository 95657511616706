// theme constant
export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;

// Developer Constants
export const SET_TOKEN = 'SET_TOKEN';
export const GET_TOKEN = 'GET_TOKEN';
export const TOKEN_ERROR = 'TOKEN_ERROR';

// Notifcaiton Constants
export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';

// Header Settings Constants
export const ALL_HEADER_SETTING_REQUEST = 'ALL_HEADER_SETTING_REQUEST';
export const ALL_HEADER_SETTING_SUCCESS = 'ALL_HEADER_SETTING_SUCCESS';
export const ALL_HEADER_SETTING_ERROR = 'ALL_HEADER_SETTING_ERROR';

export const UPDATE_HEADER_SETTING_REQUEST = 'UPDATE_HEADER_SETTING_REQUEST';
export const UPDATE_HEADER_SETTING_SUCCESS = 'UPDATE_HEADER_SETTING_SUCCESS';
export const UPDATE_HEADER_SETTING_ERROR = 'UPDATE_HEADER_SETTING_ERROR';
