import { Navigate, Outlet } from 'react-router-dom';

// project imports
import Customization from '../Customization';

import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';
import { get_token_handler } from 'store/actions';
import { useStateContext } from 'contexts/ContextProvider';

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => {
    const { ctoken } = useStateContext();
    if (ctoken) {
        return <Navigate to="/" />;
    }
    return (
        <>
            <Outlet />
            <Customization />
        </>
    );
};

const mapStateProps = (state) => ({
    token: state.DeveloperReducer
});

export default connect(mapStateProps, { get_token_handler })(MinimalLayout);
