import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { useEffect } from 'react';
import './assets/images/css/style.css';
import { useStateContext } from 'contexts/ContextProvider';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { useState } from 'react';
// ==============================|| APP ||============================== //

const App = () => {
    const [content, setContent] = useState(null);
    const customization = useSelector((state) => state.customization);
    const { notification } = useStateContext();
    useEffect(() => {
        if (notification) {
            if (notification.length > 0) {
                if (notification[0] === 200) {
                    toast.success(notification[1], {
                        position: 'bottom-right',
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored'
                    });
                } else {
                    toast.error(notification[1], {
                        position: 'bottom-right',
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored'
                    });
                }
            }
        }
    }, [notification]);
    useEffect(() => {
        if (!content) {
            axios(`${process.env.REACT_APP_API_KEY}/api/get/main/`).then(({ data }) => {
                console.log(data);
                setContent(data[0]);
            });
        }
    }, [content]);
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                {content && <title>{content.title}</title>}
                {/* <title>Apartment Goats | Chicago Luxury Apartments for Rent Service</title> */}
                {content && content.favicon && <link rel="icon" href={process.env.REACT_APP_API_KEY + content.favicon} />}
                {content && content.favicon && <link rel="apple-touch-icon" href={process.env.REACT_APP_API_KEY + content.favicon} />}
                {content && content.favicon && <link rel="manifest" href={process.env.REACT_APP_API_KEY + content.favicon} />}
                <link rel="canonical" href="http://mysite.com/example" />
                {content &&
                    content.Main_metadata &&
                    content.Main_metadata.length > 0 &&
                    content.Main_metadata.map((c, i) => <meta name={c.name} content={c.content} />)}
            </Helmet>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={themes(customization)}>
                    <CssBaseline />
                    <NavigationScroll>
                        <Routes />
                    </NavigationScroll>
                </ThemeProvider>
            </StyledEngineProvider>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                them
            />
        </>
    );
};

export default App;
