// assets
import { IconWindmill, IconWorld, IconNotebook } from '@tabler/icons';

// constant
const icons = {
    IconWindmill,
    IconWorld,
    IconNotebook
};

// ==============================|| SETTINGS MENU ITEMS ||============================== //

const blogs = {
    id: 'blogs',
    title: 'Blogs',
    type: 'group',
    children: [
        {
            id: 'blog',
            title: 'Blogs',
            type: 'collapse',
            icon: icons.IconNotebook,
            children: [
                {
                    id: 'blogs-list',
                    title: 'Blog List',
                    type: 'item',
                    url: '/blog/list',
                    breadcrumbs: false
                },
                {
                    id: 'add-blog',
                    title: 'Add Blog',
                    type: 'item',
                    url: '/blog/add',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default blogs;
