import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const Feature = Loadable(lazy(() => import('views/feature')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));
const HeaderSetting = Loadable(lazy(() => import('views/settings/site/HeaderSetting')));
const FooterSetting = Loadable(lazy(() => import('views/settings/site/FooterSetting')));
const BlogList = Loadable(lazy(() => import('views/blogs/BlogList')));
const AddBlog = Loadable(lazy(() => import('views/blogs/AddBlog')));
const EditBlog = Loadable(lazy(() => import('views/blogs/EditBlog')));
const HomePage = Loadable(lazy(() => import('views/settings/page/HomePage')));
// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: '/apartment/feature',
            element: <Feature />
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-typography',
                    element: <UtilsTypography />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-color',
                    element: <UtilsColor />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-shadow',
                    element: <UtilsShadow />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'tabler-icons',
                    element: <UtilsTablerIcons />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'material-icons',
                    element: <UtilsMaterialIcons />
                }
            ]
        },
        {
            path: 'sample-page',
            element: <SamplePage />
        },
        {
            path: 'settings',
            children: [
                {
                    path: 'header',
                    element: <HeaderSetting />
                },
                {
                    path: 'footer',
                    element: <FooterSetting />
                },
                {
                    path: 'homepage',
                    element: <HomePage />
                }
            ]
        },
        {
            path: 'blog',
            children: [
                {
                    path: 'list',
                    element: <BlogList />
                },
                {
                    path: 'add',
                    element: <AddBlog />
                },
                {
                    path: 'edit/:id',
                    element: <EditBlog />
                }
            ]
        }
    ]
};

export default MainRoutes;
