import { createStore } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

import reducer from './reducer';

// ==============================|| REDUX - MAIN STORE ||============================== //

const store = configureStore({
    reducer: reducer,
    middleware: [thunk]
});
const { dispatch } = store;
const persister = '';

export { store, persister, dispatch };
